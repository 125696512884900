<template>
  <div class="integral_page">
    <div class="pesban">
      <img src="../../assets/img/pes/jfban.png" alt="量化积分" />
    </div>
    <div class="jfDesc">
      <div class="icon"></div>
      <div class="tit">积分的重要作用</div>
      <div class="desc">
        <div>1. 申请足球特色幼儿园的重要评判标准之一。</div>
        <div>2. 可参加线下嘉年华活动，可兑换奖品，具体形式以网站通告为准。</div>
        <div>3. 在prefootball体系中，可参加学校，老师和孩子的评比。</div>
      </div>
    </div>
    <div class="points">
      <div class="head">
        您的幼儿园总积分:<span>{{ schoolIntegrals }}</span>
      </div>
      <div class="connt">
        <div class="col">
          <div class="tit ">幼儿园基础积分</div>
          <div class="box ">
            <div class="with-icon">
              <img src="../../assets/img/pes/icon-1.png" alt="幼儿园基础积分" />
              <span>{{ initIntegral }}</span>
            </div>
            <div class="btn" @click="toDetail('school')">查看积分</div>
          </div>
        </div>
        <div class="col">
          <div class="tit ">教师总积分</div>
          <div class="box ">
            <div class="with-icon">
              <img src="../../assets/img/pes/icon-2.png" alt="教师总积分" />
              <span>{{ teachersIntegral }}</span>
            </div>
            <div class="btn" @click="toList('teacher')">查看积分</div>
          </div>
        </div>
        <div class="col">
          <div class="tit ">学生总积分</div>
          <div class="box ">
            <div class="with-icon">
              <img src="../../assets/img/pes/icon-3.png" alt="学生总积分" />
              <span>{{ stuIntegral }}</span>
            </div>
            <div class="btn" @click="toList('stu')">查看积分</div>
          </div>
        </div>
      </div>
    </div>
    <div class="hpoint">
      <div class="head tab" @click="toHistory">
        <img src="../../assets/img/pes/icon-his.png" alt="查看历史积分" />
        <span>查看历史积分</span>
      </div>
      <div class="dtext">
        <div class="tit">积分的常用获取方式</div>
        <div class="tlist">
          <div class="link">1.教师上课</div>
          <div class="link">2.教师评分</div>
          <div class="link">3.学生上课</div>
          <div class="link">4.进行PDA测试</div>
          <div class="link">5.生成各种测试报告</div>
          <div class="link">6.参加小小运动会</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'integral',
    components: {},
    data() {
      return {
        initIntegral: '',
        schoolIntegrals: '',
        stuIntegral: '',
        teachersIntegral: '',
      }
    },
    created() {},
    mounted() {
      this.getInfo()
    },
    methods: {
      getInfo() {
        this.api.admin
          .schoolRankingDetail({ schoolId: this.$store.state.userInfo.schoolId })
          .then((res) => {
            // console.log(res)
            this.initIntegral = res.data.initIntegral
            this.schoolIntegrals = res.data.schoolIntegrals.toFixed(2)
            this.stuIntegral = res.data.stuIntegral
            this.teachersIntegral = res.data.teachersIntegral
          })
      },
      toList(val) {
        this.$router.push({
          path: '/admin/integralList',
          query: {
            type: val,
          },
        })
      },
      toDetail(val) {
        this.$router.push({
          path: '/admin/integralDetailList',
          query: {
            types: val,
          },
        })
      },
      toHistory() {
        this.$router.push({
          path: '/admin/historyIntegral'
        })
      }
    },
  }
</script>

<style lang="scss" scoped>
  .integral_page {
    width: 1400px;
    margin: 0 auto;

    .pesban {
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }

    .jfDesc {
      height: 230px;
      background: url(../../assets/img/pes/jftxt.png) center no-repeat;
      background-size: cover;
      position: relative;
      margin-top: 70px;

      .icon {
        background: url(../../assets/img/pes/bt.png) center no-repeat;
        background-size: cover;
        position: absolute;
        z-index: 109;
        left: 40px;
        top: -25px;
        width: 50px;
        height: 50px;
      }

      .tit {
        border-width: 3px;
        border-color: rgb(255, 255, 255);
        border-style: solid;
        border-radius: 10px;
        background-color: rgb(40, 74, 160);
        position: absolute;
        width: 324px;
        height: 54px;
        line-height: 54px;
        color: #fff;
        text-align: center;
        font-size: 34px;
        left: 65px;
        top: -30px;
        z-index: 99;
        font-weight: bold;
      }

      .desc {
        font-size: 24px;
        line-height: 40px;
        color: #fff;
        padding-left: 115px;
        padding-top: 60px;
      }
    }

    .points {
      .head {
        background: #fae8ea;
        height: 80px;
        line-height: 80px;
        font-size: 28px;
        text-align: center;

        span {
          color: #cb1b31;
          font-size: 40px;
        }
      }

      .connt {
        display: flex;
        justify-content: space-between;
        padding-top: 50px;
        margin-left: -15px;
        margin-right: -15px;

        .col {
          flex: 1;
          text-align: center;
          padding: 0px 15px;

          .tit {
            width: 290px;
            height: 64px;
            font-weight: bold;
            line-height: 64px;
            background: #284aa0;
            color: #fff;
            text-align: center;
            display: inline-block;
            margin: 0 auto;
            font-size: 34px;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;
          }

          .box {
            background: #fae8ea;
            padding: 50px 40px;

            .with-icon {
              background: #fff;
              height: 50px;
              padding: 20px 0;
              font-size: 34px;
              line-height: 50px;

              img {
                width: 50px;
                height: 50px;
                margin-right: 30px;
              }
            }

            .btn {
              height: 64px;
              line-height: 64px;
              color: #fff;
              text-align: center;
              width: calc(100% - 96px);
              border-radius: 15px;
              margin-top: 40px;
              margin-left: 48px;
              font-size: 24px;
              display: block;
              background-color: #cb1b31;
              border: none;
              box-sizing: border-box;
              transition: all 0.3s;
              cursor: pointer;
            }

            .btn:hover {
              background-color: #284aa0;
            }
          }
        }
      }
    }

    .hpoint {
      height: auto;
      margin-bottom: 80px;

      .head {
        background-color: rgb(203, 27, 49);
        height: 60px;
        line-height: 60px;
        font-size: 24px;
        color: #fff;
        text-align: center;
        display: block;

        img {
          width: 32px;
          height: auto;
          margin-right: 16px;
        }
      }

      .dtext {
        background: #eeeeee;
        margin-top: 60px;
        padding-top: 30px;
        text-align: left;

        .tit {
          font-size: 34px;
          line-height: 34px;
          text-align: center;
          margin-bottom: 10px;
        }

        .tlist {
          font-size: 24px;
          line-height: 24px;
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 50px;
          padding-left: 100px;
          padding-right: 100px;

          .link {
            width: 33.33%;
            margin-top: 30px;
          }
        }
      }
    }
  }
</style>